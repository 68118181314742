import React from "react";
import styled from "styled-components";
// Components
import PricingTable from "../Elements/PricingTable";

export default function Pricing() {
  return (
    <Wrapper id="pricing">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Check Our Pricing</h1>
            {/* <p className="font13">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
              <br />
              labore et dolore magna aliquyam erat, sed diam voluptua.
            </p> */}
          </HeaderInfo>
          <TablesWrapper className="flexSpaceNull">
            <TableBox>
              <PricingTable
                icon="roller"
                price="₹799"
                title="Starter"
                text="Application filing for GSTIN."
                offers={[
                  { name: "Generate ARN & TRN number", cheked: true },
                  { name: "Call, Chat, Email Support", cheked: true },
                  { name: "Personally assigned GST Expert", cheked: true },
                  { name: "Support Available in English and Hindi", cheked: true },
                ]}
                // action={() => alert("clicked")}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="monitor"
                price="₹4999"
                title="Basic"
                text="GST Registration absolutely FREE"
                offers={[
                  { name: "GST Return Filing for 12 Months", cheked: true },
                  { name: "Call, Chat, Email Support", cheked: true },
                  { name: "Personally assigned GST Expert", cheked: true },
                  { name: "Support Available in English and Hindi", cheked: true },
                ]}
                // action={() => alert("clicked")}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="browser"
                price="₹7999"
                title="Golden"
                text="GST Registration absolutely FREE"
                offers={[
                  { name: "GST Return Filing for 12 Months", cheked: true },
                  { name: "Call, Chat, Email Support", cheked: true },
                  { name: "Personally assigned GST Expert", cheked: true },
                  { name: "Support Available in English and Hindi", cheked: true },
                ]}
                // action={() => alert("clicked")}
              />
            </TableBox>
          </TablesWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;
const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  width: 31%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto
  }
`;




